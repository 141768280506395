import React from "react";
import { Footer, Navbar } from "../../components";
import agenttoolbar from "../../../assets/images/agenttoolbar.png";
import agenttoolbarstatus from "../../../assets/images/agenttoolbarstatus.png";
import callscript from "../../../assets/images/callscript.png";
import scripts from "../../../assets/images/scripts.gif";
import qconfig from "../../../assets/images/qconfig.png";

const Products = () => {
  return (
    <>
      <Navbar />
      <div className="h-full flex flex-1 items-center justify-center">
        <div className="h-full flex flex-.9 items-center justify-between">
          <div className="w-1/2 h-full flex items-top pt-40 justify-start flex-col">
            <h1 className="font-extrabold font-poppins text-4xl">
              Web Based Platform
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>
                  No software to install, just point your users to their web
                  browser.
                </li>
                <li>
                  Compatible with Mac, Windows, Linux, Smartphones, and Tablets.
                </li>
                <li>Quickly deployed and Zero downtime needed for updates.</li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              Advantages
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>
                  Fully web-based software, can be hosted or placed on premise
                </li>
                <li>
                  Updates can be peformed on-the-fly, without interrupting users
                </li>
                <li>
                  Skills per agent, weights per queue, priorities per queue
                </li>
                <li>
                  Realtime Audio/Visual, SMS, and Email based user-defined
                  alerts
                </li>
                <li>Virtualization support</li>
                <li>Powerful Call Scripting Engine with advanced logic</li>
                <li>Blended outbound/inbound dialer ACD functionality</li>
                <li>ACD routing</li>
                <li>Email ACD routing</li>
                <li>Live Chat ACD routing</li>
                <li>Text to Speech integration</li>
                <li>Powerful API for 3rd party integration</li>
                <li>
                  Schedule and email any report with the EQ History Engine
                </li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              Realtime Monitor
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Graphical and Tabular reports</li>
                <li>SMS/Email/Audio/Visual Alerts for custom thresholds</li>
                <li>Supervisor Chat with Agents and other Supervisors</li>
                <li>Call Queue Statistics</li>
                <li>Agent Call Statistics</li>
                <li>Monitor Calls</li>
                <li>Barge-in Calls</li>
                <li>Whisper to Agent directly</li>
                <li>View agent status with ANI and Pause Codes</li>
                <li>Dynamic Agent Login/Logout Manager</li>
                <li>Extensive Email Queue Reports</li>
                <li>View Calls in Queue Details with Customer info</li>
                <li>Parking Lot view</li>
                <li>Conference Viewer w/ mute controls</li>
                <li>Realtime Event Viewer</li>
                <li>View All Phone Status report (every phone on the PBX)</li>
                <li>Visual and Audible custom alerts</li>
                <li>Detailed Campaign Activity Reports</li>
                <li>Detailed Email Activity Reports</li>
                <li>Detailed Live Chat Activity Reports</li>
                <li>Graphical Calls in Queue and Agent Status Reports</li>
                <li>Pause/Unpause Agents in 1 or all queues</li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              Agent Toolbar
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Advanced Phone Integration and control</li>
                <li>Line per Queue display, with calls waiting</li>
                <li>Line per Email display, with emails waiting</li>
                <li>Line per Live Chat display, with live chats waiting</li>
                <li>Agent Queue status available on Polycom phone display</li>
                <li>Phone actions: Answer, Dial, Blind Transfer, Hangup</li>
                <li>Record calls on demand button</li>
                <li>Screen Pops and CRM Integration</li>
                <li>Agent scripting tool with branching/interactive flow</li>
                <li>PC Less Agent operation</li>
                <li>Parking Lot view</li>
                <li>Conference view</li>
                <li>
                  Phone book with Global Speed Dials and User-Defined, all click
                  to dial
                </li>
                <li>Chat with other Agents/Supervisors/Custom Groups</li>
                <li>Chat with online website customers (Web Chat)</li>
                <li>Email Queues</li>
                <li>All phone status view</li>
                <li>Custom note field, attaches to CDR</li>
                <li>Customer History view</li>
                <li>Outbound Campaign Call Dispositioning</li>
                <li>Call Recording Control (Permission-based)</li>
                <li>Custom Pause/UnPause codes per Queue</li>
                <li>Blended ACD call, email, and webchat queues</li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              History Engine
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Graphical and Tabular reports</li>
                <li>Cradle-to-Grave Call (C2G) Reporting on-click</li>
                <li>
                  1-Click email of CDR and C2G data along with call recording
                </li>
                <li>Scheduled reports in PDF, CSV, XLS</li>
                <li>Agent Cradle-to-Grave Activity Reporting</li>
                <li>ACD Detail Reports</li>
                <li>Non-ACD Detail Reports</li>
                <li>ACD Email Detail Reports</li>
                <li>ACD Live Chat Reports</li>
                <li>SLA Reporting</li>
                <li>Abandoned Rate Reports</li>
                <li>Over 30 Tabular Reports to Customize</li>
                <li>
                  Over 20 Graphical Reports all by Agent, Campaign, or Queue
                </li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              Administration
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Inbound ACD Queues</li>
                <li>
                  Outbound Predictive/Progressive/Automated Dialer Campaigns
                </li>
                <li>Inbound/Outbound Blended Queue Functionality</li>
                <li>Inbound Email ACD Queuing</li>
                <li>Inbound Live Chat ACD Queuing</li>
                <li>Skill-based Routing</li>
                <li>Permission-based Chat for users, with groups</li>
                <li>User access and permissions control</li>
                <li>Auto Answer ACD</li>
                <li>SMS/Email/Audio/Visual Alerts Configuration</li>
                <li>Custom Pause Code Editor</li>
                <li>Agent Script Editor with branching/interactive flow</li>
                <li>Screen Pop and CRM integration config</li>
                <li>Disk usage and Processes SMS/Email alerts</li>
                <li>Real time license usage</li>
                <li>1-Click UDP/SIP Packet Capture</li>
                <li>Disk usage graphs</li>
                <li>Software Update Push Notifier</li>
                <li>1-Click license update and database backup</li>
              </ul>
            </p>
          </div>
          <div className="w-1/2 h-full flex flex-col">
              <img className="my-[100px]" src={agenttoolbar} alt="" />
              <img className="my-[100px]" src={agenttoolbarstatus} alt="" />
              <img className="my-[100px]" src={callscript} alt="" />
              <img className="my-[100px]" src={scripts} alt="" />
              <img className="my-[100px]" src={qconfig} alt="" />
          </div>
        </div>
      </div>
      <div className="mt-20">
        <Footer />
      </div>
    </>
  );
};

export default Products;
