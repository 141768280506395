import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="flex flex-1.2 bg-gradient-to-r from-sky-400 via-cyan-300 to-blue-500 h-24 shadow-2xl">
      <div className="flex flex-1 items-center justify-center">
        <h1 className="text-4xl font-black text-white font-poppins"><Link to='/'>Equeues</Link></h1>
      </div>

      <div className="flex-1">

      </div>

      <div className="flex flex-2 items-center justify-center">
          <div className="flex flex-.5 h-full justify-between items-center text-slate-50 font-poppins font-black text-lg">
                <a className="hover:border-b-2" href='/'><Link to='/products'>Products</Link></a>
                <a className="hover:border-b-2" href='/'><Link to='/solutions'>Solutions</Link></a>
                <a className="hover:border-b-2" href='/'><Link to='/api'>API</Link></a>
          </div>
      </div>
    </div>
  );
};

export default Navbar;
