import React from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
import InputMask from "react-input-mask";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Signup = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("1-50");
  const [phone, setPhone] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.isEmail(email)) {
      setError("Please enter a valid email");
      return;
    }

    if (phone.includes("⎯")) {
      setError("Please enter a valid phone number");
      return;
    }

    emailjs.sendForm("service_83pt5c8", "template_act6jrj", e.target, '3-Mc4m702gihIOsMw').then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
    e.target.reset();

    navigate("/success");
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  return (
    <div className="w-full h-screen flex flex-1 items-center justify-center bg-gradient-to-r from-teal-400 via-cyan-500 to-sky-500">
      <div className="rounded-xl flex items-center justify-center h-4/5 w-5/12 bg-white flex-col">
        <h1 className="font-bold text-2xl py-3 border-black bg-black border-b-2 w-full text-center rounded-t-lg text-white">
          EQUEUES CONTACT FORM
        </h1>
        {error && (
          <div className="w-full flex items-center justify-center bg-red-600 h-14 text-white font-extrabold text-lg">
            {error}
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          className="h-full w-full flex flex-col px-8 justify-around font-poppins"
        >
          <label className="text-center text-lg w-full font-bold flex items-center justify-center">
            <div className="min-w-[200px]">
              <div className="text-center h-full flex items-center justify-center ">
                Name
              </div>
            </div>
            <input
              className="w-1/2 bg-gray-300 mr-8 rounded-sm h-12 pl-2 outline-none font-normal placeholder-black placeholder-opacity-50"
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              required
            />
            <input
              className="w-1/2 bg-gray-300 rounded-sm h-12 pl-2 outline-none font-normal placeholder-black placeholder-opacity-50"
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </label>

          <label className="text-center text-lg w-full font-bold flex items-center">
            <div className="min-w-[200px]">
              <div className="text-center h-full flex items-center justify-center ">
                Email
              </div>
            </div>
            <input
              className="bg-gray-300 rounded-sm h-12 w-full pl-2 outline-none"
              type="text"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          <label className="text-center text-lg w-full font-bold flex items-center">
            <div className="min-w-[200px]">
              <div className="h-full flex items-center justify-center ">
                Phone
              </div>
            </div>
            <InputMask
              mask="(999) 999-9999"
              className="bg-gray-300 h-12 w-full outline-none rounded-sm placeholder-black placeholder-opacity-50 pl-2 font-light"
              type="tel"
              maskChar="⎯"
              autoFocus
              name="phone"
              onChange={handlePhone}
              required
            />
          </label>

          <label className="text-lg w-full font-bold flex items-center">
            <div className="min-w-[200px]">
              <div className="text-center h-full flex items-center justify-center ">
                Company
              </div>
            </div>
            <input
              className="bg-gray-300 h-12 w-3/4 mr-6 rounded-sm pl-2 text-black placeholder-black placeholder-opacity-50 outline-none"
              type="text"
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Name"
              name="companyName"
            />
            <div className="flex items-center h-full w-2/4 justify-center">
              Employees
            </div>
            <select
              className="text-center h-12 bg-gray-300 outline-none"
              onChange={(e) => setCompanySize(e.target.value)}
              name="companySize"
            >
              <option>1-50</option>
              <option>50-150</option>
              <option>150-300</option>
              <option>300-500</option>
              <option>500+</option>
            </select>
          </label>

          <button
            type="submit"
            className="rounded-md py-4  mx-32 font-extrabold bg-sky-900 text-white"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
