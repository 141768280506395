import React from 'react'
import { Link } from 'react-router-dom'

const Success = () => {
  return (
    <div className='flex flex-1 h-screen w-full bg-gradient-to-r from-cyan-400 via-cyan-900 to-blue-500 items-center justify-center'>
        <div className='w-1/3 h-1/2 border-2 border-sky-300 rounded-md bg-sky-300 flex items-center justify-center flex-col'>
            <h1 className='font-bold font-poppins w-3/4 text-2xl'>Success. Your contact info has been sent to a support member at equeues. We will email you back soon.</h1>
            <button className='border-sky-300 border-2 px-8 py-2 text-lg bg-sky-800 rounded-lg mt-10 font-poppins font-black text-white'><Link to='/'>Homepage</Link></button>
        </div>
    </div>
  )
}

export default Success