import React from 'react'
import manycalls from '../../../assets/images/manycalls.png'

const HomeSolutions = () => {
  return (
    <div className='h-full w-full flex flex-1 justify-center items-center py-80 '>
        <div className='flex flex-.9 justify-around items-top'>
            <h1 className='font-poppins font-bold text-2xl w-1/3 border-r-2 pr-2 border-black' >Designed to meet demands of real world contact center environments. Can be placed as a stand alone contact center solution or an addition to a business phone system.</h1>

            <img src={manycalls} alt='manyCalls' className='w-[1200px] px-12'/>

            <h1 className='w-1/3 font-poppins font-bold text-2xl border-l-2 pl-2 border-black' >Servers can be started within 30 minutes of signing up and include locations in Dallas, Atlanta, Frankfurt, Newark, Fremont, Singapore, Tokyo, and London. Can be hosted in cloud or on premises</h1>
        </div>
    </div>
  )
}

export default HomeSolutions