import React from 'react'
import { Banner, Footer, HomeProducts, HomeSolutions, Navbar } from '../../components'

const Home = () => {
  return (
    <div>
        <Navbar />
        <Banner />
        <HomeProducts />
        <HomeSolutions />
        <Footer />
    </div>
  )
}

export default Home