import React from "react";
import contact from "../../../assets/images/contact.svg";
import agenttoolbar from "../../../assets/images/agenttoolbar.png";

const HomeProducts = () => {
  return (
    <div className="h-screen flex flex-col justify-center bg-sky-200">
      <div className="flex h-1/2 w-full flex-.8 justify-around items-center">
        <h1 className="flex items-center w-1/3 font-bold font-poppins text-3xl border-b-2">
          A realtime and robust contact center, built using the latest PBX
          technologies.
        </h1>
        <img src={contact} alt="contact" />
      </div>
      <div className="flex h-1/2 w-full justify-between px-20 items-center">
        <img src={agenttoolbar} alt="toolbar" className="h-full" />

        <h1 className="flex items-center w-1/4 border-l-2 border-black pl-5 font-bold font-poppins text-xl ">
          With equeues contact center, you get a cross platform web based
          contact center with an easy to use API. Administrators can use the
          realtime monitor, agent toolbar, or history engine.
        </h1>
      </div>
    </div>
  );
};

export default HomeProducts;
