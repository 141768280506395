import React from "react";
import { Link } from "react-router-dom";
import deal from "../../../assets/images/deal.svg";

const Banner = () => {
  return (
    <div className="h-full flex flex-1 items-center justify-center py-32">
      <div className="h-full flex flex-.8 justify-between items-center ">
        <div>
          <img src={deal} alt='businessdeal' />
        </div>

        <div className="text-center flex flex-col flex-1 items-center justify-center">
          <h1 className="font-extrabold text-5xl font-poppins drop-shadow-xl">
            A Web Based Contact Center built to be intuitive, adaptive, and
            portable.
          </h1>
          <button className="mt-5 py-3 px-6 rounded-md border-blue-500 bg-blue-500 border-4 font-black text-white font-poppins text-xl hover:bg-blue-800 hover:border-blue-800"><Link to='/contact'>Contact Us</Link></button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
