import React from "react";
import { Footer, Navbar } from "../../components";

const Solutions = () => {
  return (
    <>
      <Navbar />
      <div className="h-full flex flex-1 items-center justify-center">
        <div className="h-full flex flex-.9 items-center justify-center">
          <div className="w-1/2 h-full flex items-top pt-40 justify-center flex-col">
            <h1 className="font-extrabold font-poppins text-4xl">Ecommerce</h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Guarantee your customer satisfaction</li>
                <li>Analyze and reduce abandoned calls</li>
                <li>Manage staffing using our intuitive reports</li>
                <li>Skill-based Routing</li>
                <li>Supervisor and Agent Chat to quickly address questions</li>
                <li>Call Recording monitor agents</li>
                <li>Agents have presence, and can see calls in queue</li>
                <li>Advertise using Outbound Campaigns</li>
                <li>Predictive or Progressive Dialer</li>
                <li>Agent Scripts for easy dictation</li>
                <li>Salesforce integration for quick customer look up</li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              Help Desk
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Record calls for auditing and training</li>
                <li>Record-on-Demand permission for Agent control</li>
                <li>Custom note entry for Agent per call (Ticket #)</li>
                <li>Salesforce integration</li>
                <li>Monitor Calls</li>
                <li>Barge-in Calls</li>
                <li>Whisper to Agent directly</li>
                <li>Report on calls per specific customer DNIS</li>
                <li>
                  Chat with other Agents and Supervisors for immediate
                  assistance
                </li>
                <li>Parking Lot view for easy transfers</li>
                <li>Visual and Audible custom alerts</li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              Healthcare
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Route by skill for specific Queues</li>
                <li>Enable Queue priority for specific customers</li>
                <li>Voice recognition</li>
                <li>Text to Speech</li>
                <li>Monitor Calls</li>
                <li>Reduce hold time</li>
                <li>SMS/Email alerts with custom thresholds</li>
              </ul>
            </p>

            <h1 className="font-extrabold font-poppins text-4xl pt-24">
              Financial Institutions
            </h1>
            <p className="font-poppins text-2xl pt-6">
              <ul className="list-disc">
                <li>Use CRM integration to identify customers quickly</li>
                <li>Skill-based routing</li>
                <li>Voice recognition</li>
                <li>Database integration for balance inquiries</li>
                <li>Record Calls</li>
                <li>Agent presence across all Queues</li>
                <li>Agent Cradle-to-Grave reporting</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <Footer />
      </div>
    </>
  );
};

export default Solutions;
