import React from "react";
import "./style.css";
import "./normalize.min.css";

const Api = () => {
  return (
    <div className='body'>
      <nav id="menu">
        <ul>
          <li>
            <a href="#introduction">Introduction</a>
          </li>
          <li>
            <a href="#base_url">Base URL</a>
          </li>
          <li>
            <a href="#authentication">Authentication</a>
          </li>
          <li>
            <a href="#json_parameters">JSON Parameters</a>
          </li>
          <li>
            <a href="#deleted_entities">Deleted Entities</a>
          </li>
          <li>
            <a href="#user_sessions">User Sessions</a>
          </li>
          <li>
            <a href="#date_times">Date/Times</a>
          </li>
          <li>
            <a href="#errors">Errors</a>
          </li>
          <li>
            <a href="#endpoints">Endpoints</a>
            <ul>
              <li>
                <a href="#endpoints-supervisor_session">Supervisor Session</a>
              </li>
              <li>
                <a href="#endpoints-agent_session">Agent Session</a>
              </li>
              <li>
                <a href="#endpoints-supervisor">Supervisor</a>
              </li>
              <li>
                <a href="#endpoints-agent">Agent</a>
              </li>
              <li>
                <a href="#endpoints-call_queue">Call Queue</a>
              </li>
              <li>
                <a href="#endpoints-pause_code">Pause Code</a>
              </li>
              <li>
                <a href="#endpoints-extension">Extension</a>
              </li>
              <li>
                <a href="#endpoints-phone">Phone</a>
              </li>
              <li>
                <a href="#endpoints-alert">Alert</a>
              </li>
              <li>
                <a href="#endpoints-active_supervisor_sessions">
                  Active Supervisor Sessions
                </a>
              </li>
              <li>
                <a href="#endpoints-active_agent_sessions">
                  Active Agent Sessions
                </a>
              </li>
              <li>
                <a href="#endpoints-queued_calls">Queued Calls</a>
              </li>
              <li>
                <a href="#endpoints-steal_queued_call">Steal Queued Call</a>
              </li>
              <li>
                <a href="#endpoints-parked_calls">Parked Calls</a>
              </li>
              <li>
                <a href="#endpoints-pick_up_parked_call">Pick Up Parked Call</a>
              </li>
              <li>
                <a href="#endpoints-active_calls">Active Calls</a>
              </li>
              <li>
                <a href="#endpoints-call_recordings">Call Recordings</a>
              </li>
              <li>
                <a href="#endpoints-call_queue_agent_status">
                  Call Queue Agent Status
                </a>
              </li>
              <li>
                <a href="#endpoints-call_queue_agent_action">
                  Call Queue Agent Action
                </a>
              </li>
              <li>
                <a href="#endpoints-call_queue_statistics">
                  Call Queue Statistics
                </a>
              </li>
              <li>
                <a href="#endpoints-extension_action">Extension Action</a>
              </li>
              <li>
                <a href="#endpoints-call_recording_action">
                  Call Recording Action
                </a>
              </li>
              <li>
                <a href="#endpoints-call_monitor_action">Call Monitor Action</a>
              </li>
              <li>
                <a href="#endpoints-phone_command">Phone Command</a>
              </li>
              <li>
                <a href="#endpoints-active_conferences">Active Conferences</a>
              </li>
              <li>
                <a href="#endpoints-license">License</a>
              </li>
              <li>
                <a href="#endpoints-license_usage">License Usage</a>
              </li>
              <li>
                <a href="#endpoints-configuration_check">Configuration Check</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <div className='content'>

      <a name="introduction"></a>
      <h2>Introduction</h2>
      <p>
        The EQ API is a REST API, which means it uses resource-oriented URLs and
        makes use of GET, POST, PUT, and DELETE methods to determine the type of
        action desired. Standard HTTP response codes are used to signify whether
        the request was successful, and if not, what went wrong; any further
        error details are included in the body of the response. All data is
        returned as JSON.
      </p>
      <p>
        The API is versioned independently of the EQ software package version to
        ensure that thirty-party clients relying on the API will not be broken
        with software updates. A certain version of the API can always be
        expected to work exactly the same way and return data in exactly the
        same format. New features may be added to an existing version of the
        API, but existing features will not be changed in any way that would
        break functionality depending on them.
      </p>
      <a name="base_url"></a>
      <h2>Base URL</h2>
      <p>
        The endpoint URLs are dependent on the domain name or IP address of the
        server on which EQ is installed. The base URL is therefore:
      </p>
      <div class="url">http://[server]/eq/api/2.0</div>
      <p>where [server] is an IP address or domain name.</p>
      <p>
        All endpoint URLs are relative to the base URL. The full URL for the
        supervisor-session endpoint, for example, would then be:
      </p>
      <div class="url">http://[server]/eq/api/2.0/supervisor-session</div>
      <a name="authentication"></a>
      <h2>Authentication</h2>
      <h3>API Key</h3>
      <p>
        All requests to the API must be authenticated by a valid API key
        generated on the EQ system. API keys can be generated in EQ
        Administration.
      </p>
      <p>
        The API key must be passed in either the URL as a query string parameter
        called <strong>auth_key</strong> or in the body of the request if
        applicable (e.g. the POST data). Either location for the parameter is
        acceptable for POST, PUT, or DELETE requests. However, due to the nature
        of GET requests, which have no POST data, the key must be in the URL.
      </p>
      <p>
        For the sake of consistency, it is recommended to always pass the key in
        the URL as a query string parameter.
      </p>
      <p>If the key is passed in the URL, the base URL becomes:</p>
      <div class="url">http://[server]/eq/api/2.0?auth_key=[key]</div>
      <h3>User Session</h3>
      <p>
        API keys requests are always authenticated by the provided API key.
        However, requests can optionally be additionally authenticated by a user
        session token if the request is done on behalf of a certain user.
      </p>
      <p>
        <strong>
          To maintain proper historical data, such as activity logs, it is
          recommended to authenticate requests as a certain user whenever
          possible.
        </strong>
      </p>
      <p>
        If a user session token is provided, then the session is validated and
        refreshed (i.e. pinged / kept alive). The entire request will fail with
        an error if the session is invalid, or if the session token is valid,
        but for circumstantial reasons, the session is inactive and cannot be
        reactivated (usually because the user is signed in elsewhere, or another
        user is signed in at the same extension). In such cases, the HTTP
        response code will be 401, and the relevant errors will be returned as
        JSON in the body of the response as with any other request.
      </p>
      <p>
        Keep in mind that any request to the API authenticated as a certain user
        will cause that user to be signed in again if they were not previously
        and the session token is valid and there are no circumstantial
        conflicts. This normally only happens if the user previously timed out.
        If the user is signed out using the API, the session is destroyed, so it
        would not be possible that any further requests would accidentally sign
        them in again.
      </p>
      <p>
        Since there are two primary types of users, supervisors and agents,
        there are two separate session tokens that can be passed, although only
        one or the other will be used for any given request. To send a request
        on behalf of a supervisor, the session token must be passed in the URL
        or the POST/PUT data as the <strong>auth_supervisor_session</strong>{" "}
        parameter. For an agent, the session token must be passed as the{" "}
        <strong>auth_agent_session</strong> parameter.
      </p>
      <p>
        Only one type of session token (supervisor or agent) should be provided
        on any given request. If both a supervisor session token and an agent
        session token are passed, the supervisor one will always be given
        priority, and the agent one will have no effect. If neither session
        token is provided, then the API request is not authenticated as any
        certain user, and a valid API key is sufficient to authenticate the
        request.
      </p>
      <p>
        Like the API key, the user session token can be provided in either the
        URL (GET, POST, PUT, or DELETE) or in the body of the request (POST,
        PUT, or DELETE).
      </p>
      <p>
        If the API key and user session token are passed in the URL, the base
        URL becomes:
      </p>
      <div class="url">
        http://[server]/eq/api/2.0?auth_key=[key]&amp;auth_supervisor_session=[token]
      </div>
      <p>or</p>
      <div class="url">
        http://[server]/eq/api/2.0?auth_key=[key]&amp;auth_agent_session=[token]
      </div>
      <a name="json_parameters"></a>
      <h2>JSON Parameters</h2>
      <p>
        All data is returned as JSON, but some endpoints accept JSON for certain
        parameters as well. For example, when creating a new agent, the agent's
        attributes must be passed as a JSON object for the <strong>data</strong>{" "}
        parameter.
      </p>
      <p>
        In general, endpoints accept/require JSON for parameters when an entire
        entity is being passed to the API, whether to create it or update it.
        Otherwise parameters are simply strings.
      </p>
      <p>
        When a JSON object is required for a parameter, some attributes of the
        object may not be required. In those cases, the attributes may be
        omitted entirely, and the default value (usually null or blank) will be
        used.
      </p>
      <a name="deleted_entities"></a>
      <h2>Deleted Entities</h2>
      <p>
        It is important to know how the EQ software treats deleted entities to
        understand some aspects of the API.
      </p>
      <p>
        For historical reporting purposes, any primary entities that could have
        historical data attached to them are never actually deleted, but are
        only flagged as deleted. Users, queues, and pause codes are some
        examples. Such entities contain a boolean <strong>deleted</strong>{" "}
        attribute. The <strong>deleted</strong> flag allows the EQ software to
        ignore the entity and treat it as nonexistent except when its details
        are needed for historical reporting purposes.
      </p>
      <p>
        Whether the API treats an entity as nonexistent based on its deleted
        status depends on the endpoint. If it makes sense to retrieve or act on
        deleted entities for a certain endpoint, then the endpoint will do so
        without complaint. However, if it only makes sense that deleted entities
        are ignored or treated as nonexistent, then the endpoint will do so and
        will throw an error if a deleted entity if specified.
      </p>
      <p>
        For example, when retrieving agent details from the API, deleted agents
        are considered existent (but deleted) and will be returned (and their{" "}
        <strong>deleted</strong> attribute will signify that they are deleted).
        However, when signing an agent in and creating a new session for them,
        it would not make sense to allow a deleted agent to sign in, so the API
        would treat the agent as nonexistent and refuse the request.
      </p>
      <p>
        In general, entities cannot be edited while in a deleted state. PUT
        requests to update a deleted entity will fail with a 404 response code.
      </p>
      <p>
        The specifications for each endpoint will clarify how deleted entities
        are treated if necessary.
      </p>
      <a name="user_sessions"></a>
      <h2>User Sessions</h2>
      <p>
        It is important to understand how user sessions work in the EQ software
        to understand some aspects of the API.
      </p>
      <p>
        Each user can only have one active session at a time. Signing in again
        or elsewhere will either fail or force out any other active sessions
        (depending on the parameters of the sign-in).
      </p>
      <p>
        If a user signs out or is forced to sign out, the session is destroyed.
        However, if a user simply times out, meaning their ping (keep alive)
        stopped for some reason, the session is deactivated, but remains it
        valid. As soon as the user pings the system again, the session is
        reactivated (if no conflicts), and the user is signed in again.
      </p>
      <p>
        A user is considered signed in if and only if they have a valid, active
        session. If a session is destroyed or deactivated, that user is no
        longer considered signed in. Once a deactivated session is reactivated,
        the user is considered signed in again.
      </p>
      <a name="date_times"></a>
      <h2>Date/Times</h2>
      <p>
        All date/times accepted or returned by the API are UNIX timestamps, or
        seconds since January 1, 1970.
      </p>
      <a name="errors"></a>
      <h2>Errors</h2>
      <h3>HTTP Response Codes</h3>
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>200</td>
            <td>The request was successful.</td>
          </tr>
          <tr>
            <td>400</td>
            <td>
              The request was not valid. Usually this code means a parameter was
              missing or its value was not valid. However, sometimes this code
              can mean that the request was rejected for circumstantial reasons,
              such as a user already being logged in when trying to create a new
              session, and in those cases, the JSON from the response body will
              provide the details needed to determine what exactly was wrong.
            </td>
          </tr>
          <tr>
            <td>401</td>
            <td>
              Either: The API key was missing or invalid. A user session token
              was provided, but the session is invalid or cannot be
              refreshed/reactivated.
            </td>
          </tr>
          <tr>
            <td>404</td>
            <td>
              The endpoint does not exist, or the resource/entity identified in
              the request was not found or was in a deleted state.
            </td>
          </tr>
          <tr>
            <td>405</td>
            <td>
              The endpoint exists, but does not support any functionality for
              the HTTP method specified.
            </td>
          </tr>
          <tr>
            <td>500</td>
            <td>
              The server encountered an unexpected error. Normally this error
              should never occur unless the server is malfunctioning or the
              software was misconfigured. However, it is also possible that this
              error occurs on rare occasions if a request parameter contains a
              value in an unexpected format that the software is not prepared to
              handle.
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Error Details in JSON</h3>
      <p>
        Except when the response code is 500 or the response code is 404 because
        the <em>endpoint</em> does not exist (as opposed to a resource/entity
        not existing), all failed requests will return an array of errors as
        JSON in the response body.
      </p>
      <p>
        The errors are returned as a numerical array containing a number of
        sub-arrays, one for each error. The sub-arrays have two string values:
        the name of a problem parameter/attribute as the first value (index 0)
        and the type of error as the second value (index 1). If the error does
        not pertain to any certain parameter/attribute, then the first value is
        null. The second value is never null.
      </p>
      <p>Example:</p>
      <div class="code">
        [ [null,"already_signed_in"], ["extension","blank"] ]
      </div>
      <p>
        The example shows two errors. These errors might occur when creating a
        new session for an agent. The first error does not pertain to any
        certain parameter/attribute, so the first value is null; the second
        value signifies that the user is already signed in elsewhere. The second
        error pertains to the <strong>extension</strong> parameter, and its
        value means the the parameter was either missing or contained a blank
        value.
      </p>
      <p>
        When the first value is <em>not</em> null, the second value (type of
        error) is usually one of a few standard values. Those values and their
        general meaning are:
      </p>
      <table>
        <thead>
          <tr>
            <th>Value</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>blank</td>
            <td>
              Parameter/attribute missing or blank (usually empty string or
              null)
            </td>
          </tr>
          <tr>
            <td>invalid</td>
            <td>Parameter/attribute not in proper format</td>
          </tr>
          <tr>
            <td>nonexistent</td>
            <td>
              Entity identified by parameter/attribute does not exist; can also
              mean entity exists, but is deleted (only in cases where retrieving
              or acting on deleted entities does not make sense)
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Most errors are only meant to aid in development, and the specific
        values of the errors in the JSON data should not be relied on. The
        client should be designed to avoid the errors by doing as much
        validation as possible before making the request. However, some errors
        for some endpoints (such as creating a user session) are circumstantial
        and unavoidable and must be handled by the client when they occur.
      </p>
      <a name="endpoints"></a>
      <h2>Endpoints</h2>
      <p>
        Each endpoint handles a certain type of entity/resource or a certain
        action on resources/entities of that type. Each endpoint supports some
        or all of the four standard HTTP methods (GET, POST, PUT, DELETE).
      </p>
      <a name="endpoints-supervisor_session"></a>
      <div class="endpoint">
        <h3>Create Supervisor Session (Sign In)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to create a new session for a supervisor
              user, i.e. sign the user in.
            </p>
          </div>
          <div class="url">POST supervisor-session</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>user</td>
                  <td>Yes</td>
                  <td>User ID of supervisor for which to create session</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>extension</td>
                  <td>No</td>
                  <td>Extension to sign supervisor in at</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>force</td>
                  <td>No</td>
                  <td>
                    1 - force new session if supervisor already signed in 0 - do
                    not force new session (will fail with error if supervisor
                    already signed in)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the token of
              the new supervisor session (64-byte string).
            </p>
            <p>Example:</p>
            <div class="code">
              06662e69707248c9aaf53a158689872c08f104ec1b5a508eeb67ef8ffdc9953f
            </div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>[null]</td>
                  <td>already_signed_in</td>
                  <td>
                    The supervisor is already signed in with another session
                  </td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>Another user is signed in at the extension</td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>nonexistent</td>
                  <td>Extension does not exist</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Keep Supervisor Session Alive (Ping)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to keep a session for a supervisor user
              alive, i.e. ping. Although sessions are automatically refreshed by
              other requests (when they are authenticated as the user), sending
              a ping regularly ensures that the session won't time out.
            </p>
          </div>
          <div class="url">PUT supervisor-session/[token]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>token</td>
                  <td>Yes</td>
                  <td>Token of the supervisor session to keep alive</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is an object
              containing information about the session refresh.
            </p>
            <p>Example:</p>
            <div class="code">&#123; "previouslyActive": true &#125;</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>token</td>
                  <td>nonexistent</td>
                  <td>The session doesn't exist or is no longer valid</td>
                </tr>
                <tr>
                  <td>[null]</td>
                  <td>already_signed_in</td>
                  <td>
                    The supervisor is already signed in with another session
                  </td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>Another user is signed in at the extension</td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>nonexistent</td>
                  <td>Extension does not exist</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Supervisor Session (Sign Out)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to delete a certain supervisor session, i.e.
              signing the user out.
            </p>
          </div>
          <div class="url">DELETE supervisor-session/[token]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>token</td>
                  <td>Yes</td>
                  <td>
                    Token of the supervisor session for which to sign user out
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-agent_session"></a>
      <div class="endpoint">
        <h3>Create Agent Session (Sign In)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to create a new session for an agent user,
              i.e. sign the user in.
            </p>
          </div>
          <div class="url">POST agent-session</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>user</td>
                  <td>Yes</td>
                  <td>User ID of agent for which to create session</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension to sign agent in at</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>force</td>
                  <td>No</td>
                  <td>
                    1 - force new session if agent already signed in 0 - do not
                    force new session (will fail with error if agent already
                    signed in)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the token of
              the new agent session (64-byte string).
            </p>
            <p>Example:</p>
            <div class="code">
              f7174339b2b7101c9df7dfc17ae9feba63138d435cf2d4b11ae59e0b185d54db
            </div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>[null]</td>
                  <td>already_signed_in</td>
                  <td>The agent is already signed in with another session</td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>blank</td>
                  <td>Extension not specified</td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>nonexistent</td>
                  <td>Extension does not exist</td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>Another user is signed in at the extension</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Keep Agent Session Alive (Ping)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to keep a session for an agent user alive,
              i.e. ping. Although sessions are automatically refreshed by other
              requests (when they are authenticated as the user), sending a ping
              regularly ensures that the session won't time out.
            </p>
          </div>
          <div class="url">PUT agent-session/[token]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>token</td>
                  <td>Yes</td>
                  <td>Token of the agent session to keep alive</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is an object
              containing information about the session refresh.
            </p>
            <p>Example:</p>
            <div class="code">&#123; "previouslyActive": true &#125;</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>token</td>
                  <td>nonexistent</td>
                  <td>The session doesn't exist or is no longer valid</td>
                </tr>
                <tr>
                  <td>[null]</td>
                  <td>already_signed_in</td>
                  <td>The agent is already signed in with another session</td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>Another user is signed in at the extension</td>
                </tr>
                <tr>
                  <td>extension</td>
                  <td>nonexistent</td>
                  <td>Extension does not exist</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Agent Session (Sign Out)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to delete a certain agent session, i.e.
              signing the user out.
            </p>
          </div>
          <div class="url">DELETE agent-session/[token]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>token</td>
                  <td>Yes</td>
                  <td>Token of the agent session for which to sign user out</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-supervisor"></a>
      <div class="endpoint">
        <h3>Get Supervisor</h3>
        <div>
          <div>
            <p>This endpoint is used to get a certain supervisor user.</p>
          </div>
          <div class="url">GET supervisor/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the supervisor</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is a supervisor
              user object.
            </p>
            <p>Example:</p>
            <div class="code">
              &#123; "id":2, "username":"bill", "firstName":"Bill",
              "lastName":"Smith", "emailAddress":"bill.smith@someplace.com",
              "language":"en", "type":1, "agreedToEula":true, "deleted":false,
              "datetimeLastUpdated":1358539596, "datetimeDeleted":null &#125;
            </div>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Create Supervisor</h3>
        <div>
          <div>
            <p>This endpoint is used to create a new supervisor user.</p>
          </div>
          <div class="url">POST supervisor</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of supervisor user to
                    create
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>username</td>
                  <td>Yes</td>
                  <td>3-20 alphanumeric characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>firstName</td>
                  <td>Yes</td>
                  <td>1-32 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>lastName</td>
                  <td>No</td>
                  <td>1-32 characters, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>password</td>
                  <td>Yes</td>
                  <td>4-20 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emailAddress</td>
                  <td>Yes</td>
                  <td>valid email address</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>language</td>
                  <td>No</td>
                  <td>
                    valid language code: en (English) es (Spanish) he (Hebrew)
                    or blank/null for default language
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>type</td>
                  <td>Yes</td>
                  <td>valid type code: full monitorOnly</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the ID of the
              new supervisor user (integer).
            </p>
            <p>Example:</p>
            <div class="code">142</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>username</td>
                  <td>in_use</td>
                  <td>
                    A supervisor with the specified username already exists
                  </td>
                </tr>
                <tr>
                  <td>emailAddress</td>
                  <td>in_use</td>
                  <td>
                    A supervisor with the specified email address already exists
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Update Supervisor</h3>
        <div>
          <div>
            <p>This endpoint is used to update an existing supervisor user.</p>
          </div>
          <div class="url">PUT supervisor/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the supervisor</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of supervisor user to
                    update
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>username</td>
                  <td>Yes</td>
                  <td>3-20 alphanumeric characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>firstName</td>
                  <td>Yes</td>
                  <td>1-32 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>lastName</td>
                  <td>No</td>
                  <td>1-32 characters, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>password</td>
                  <td>No</td>
                  <td>4-20 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emailAddress</td>
                  <td>Yes</td>
                  <td>valid email address</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>language</td>
                  <td>No</td>
                  <td>
                    valid language code: en (English) es (Spanish) he (Hebrew)
                    or blank/null for default language
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>type</td>
                  <td>Yes</td>
                  <td>valid type code: full monitorOnly</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>username</td>
                  <td>in_use</td>
                  <td>
                    A supervisor with the specified username already exists
                  </td>
                </tr>
                <tr>
                  <td>emailAddress</td>
                  <td>in_use</td>
                  <td>
                    A supervisor with the specified email address already exists
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Supervisor</h3>
        <div>
          <div>
            <p>
              This endpoint is used to delete a certain supervisor user. The
              user is not actually deleted, but is flagged as deleted and
              treated accordingly.
            </p>
          </div>
          <div class="url">DELETE supervisor/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the supervisor</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Undelete Supervisor</h3>
        <div>
          <div>
            <p>This endpoint is used to undelete a certain supervisor user.</p>
          </div>
          <div class="url">PUT supervisor/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the supervisor</td>
                </tr>
                <tr>
                  <td>Query String</td>
                  <td>undelete</td>
                  <td>Yes</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-agent"></a>
      <div class="endpoint">
        <h3>Get Agent</h3>
        <div>
          <div>
            <p>This endpoint is used to get a certain agent user.</p>
          </div>
          <div class="url">GET agent/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the agent</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is a agent user
              object.
            </p>
            <p>Example:</p>
            <div class="code">
              &#123; "id":2, "number":"604", "firstName":"Bill",
              "lastName":"Smith", "emailAddress":null, "language":null,
              "deleted":false, "datetimeLastUpdated":1358539596,
              "datetimeDeleted":null &#125;
            </div>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Create Agent</h3>
        <div>
          <div>
            <p>This endpoint is used to create a new agent user.</p>
          </div>
          <div class="url">POST agent</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of agent user to create
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>number</td>
                  <td>Yes</td>
                  <td>1-5 digits, not starting with 0</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>firstName</td>
                  <td>Yes</td>
                  <td>1-32 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>lastName</td>
                  <td>No</td>
                  <td>1-32 characters, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>password</td>
                  <td>Yes</td>
                  <td>3-10 digits</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emailAddress</td>
                  <td>No</td>
                  <td>valid email address, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>language</td>
                  <td>No</td>
                  <td>
                    valid language code: en - English es - Spanish he - Hebrew
                    or blank/null for default language
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the ID of the
              new agent user (integer).
            </p>
            <p>Example:</p>
            <div class="code">142</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>number</td>
                  <td>in_use</td>
                  <td>An agent with the specified number already exists</td>
                </tr>
                <tr>
                  <td>emailAddress</td>
                  <td>in_use</td>
                  <td>
                    An agent with the specified email address already exists
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Update Agent</h3>
        <div>
          <div>
            <p>This endpoint is used to update an existing agent user.</p>
          </div>
          <div class="url">PUT agent/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the supervisor</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of agent user to update
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>number</td>
                  <td>Yes</td>
                  <td>1-5 digits, not starting with 0</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>firstName</td>
                  <td>Yes</td>
                  <td>1-32 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>lastName</td>
                  <td>No</td>
                  <td>1-32 characters, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>password</td>
                  <td>No</td>
                  <td>3-10 digits</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emailAddress</td>
                  <td>No</td>
                  <td>valid email address, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>language</td>
                  <td>No</td>
                  <td>
                    valid language code: en - English es - Spanish he - Hebrew
                    or blank/null for default language
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>number</td>
                  <td>in_use</td>
                  <td>An agent with the specified number already exists</td>
                </tr>
                <tr>
                  <td>emailAddress</td>
                  <td>in_use</td>
                  <td>
                    An agent with the specified email address already exists
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Agent</h3>
        <div>
          <div>
            <p>
              This endpoint is used to delete a certain agent user. The user is
              not actually deleted, but is flagged as deleted and treated
              accordingly.
            </p>
          </div>
          <div class="url">DELETE agent/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the agent</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Undelete Agent</h3>
        <div>
          <div>
            <p>This endpoint is used to undelete a certain agent user.</p>
          </div>
          <div class="url">PUT agent/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>User ID of the agent</td>
                </tr>
                <tr>
                  <td>Query String</td>
                  <td>undelete</td>
                  <td>Yes</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-call_queue"></a>
      <div class="endpoint">
        <h3>Get Call Queue</h3>
        <div>
          <div>
            <p>This endpoint is used to get a certain call queue.</p>
            <p>
              <em>
                Note: Many of the call queue attributes are normally configured
                in FreePBX and are not managed or used by the EQ software
                directly. See the FreePBX queue configuration page for more
                information on any attributes that aren't self-explanatory.
              </em>
            </p>
          </div>
          <div class="url">GET call-queue/[extension]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension of the call queue</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is a call queue
              object.
            </p>
            <p>Example:</p>
            <div class="code">
              &#123; "extension": 502, "name": "Support", "type": 0,
              "autoAnswerAcd": true, "sysResume": 5, "cidNamePrefix": null,
              "agentRestrictions": 0, "ringStrategy": "ringall", "weight": 0,
              "mohClass": "inherit", "ringingMoh": false, "recordingFormat":
              "wav", "recordingMode": "includeholdtime", "maxWaitTime": null,
              "agentTimeout": 15, "agentRetryTime": 5, "maxCallers": null,
              "joinEmpty": "yes", "leaveEmpty": "no", "announceFrequency": 0,
              "announcePosition": "no", "announceHoldTime": "no", "deleted":
              false, "datetimeLastUpdated": 1368720932, "datetimeDeleted": null
              &#125;
            </div>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Create Call Queue</h3>
        <div>
          <div>
            <p>This endpoint is used to create a new call queue.</p>
            <p>
              <em>
                Note: Many of the call queue attributes are normally configured
                in FreePBX and are not managed or used by the EQ software
                directly. See the FreePBX queue configuration page for more
                information on any attributes that aren't self-explanatory.
              </em>
            </p>
          </div>
          <div class="url">POST call-queue</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of call queue to create
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>digits only, not starting with 0</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-35 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>type</td>
                  <td>Yes</td>
                  <td>valid type code: 0 (inbound) 1 (outbound)</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>autoAnswerAcd</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sysResume</td>
                  <td>No</td>
                  <td>integer &gt; 0 (seconds), or null to disable</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>wrapUpTime</td>
                  <td>No</td>
                  <td>
                    integer &gt; 0 (seconds), 0 for unlimited, or null to
                    disable
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>wrapUpAllQueues</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>cidNamePrefix</td>
                  <td>No</td>
                  <td>string, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>agentRestrictions</td>
                  <td>No</td>
                  <td>
                    valid choice: 0 (Call as Dialed) 1 (No Follow-Me or Call
                    Forward) 2 (Extensions Only)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>ringStrategy</td>
                  <td>No</td>
                  <td>
                    valid choice: ringall leastrecent fewestcalls random
                    rrmemory rrordered linear wrandom
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>weight</td>
                  <td>No</td>
                  <td>integer 0-10</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>mohClass</td>
                  <td>No</td>
                  <td>valid choice: inherit default none</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>ringingMoh</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recordingFormat</td>
                  <td>No</td>
                  <td>
                    "wav", or blank/null to disable
                    <em>
                      Note: FreePBX lists two other formats that are not
                      supported by EQ and should not be used.
                    </em>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recordingMode</td>
                  <td>No</td>
                  <td>valid choice: includeholdtime afteranswered</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>maxWaitTime</td>
                  <td>No</td>
                  <td>integer &gt; 0, or null for unlimited</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>agentTimeout</td>
                  <td>No</td>
                  <td>integer &gt; 0, or null for unlimited</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>agentRetryTime</td>
                  <td>No</td>
                  <td>integer &gt;= 0, or null for no retry</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>maxCallers</td>
                  <td>No</td>
                  <td>integer &gt; 0, or null for unlimited</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>joinEmpty</td>
                  <td>No</td>
                  <td>valid choice: yes no strict ultrastrict loose</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>leaveEmpty</td>
                  <td>No</td>
                  <td>valid choice: yes no strict ultrastrict loose</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>announceFrequency</td>
                  <td>No</td>
                  <td>integer &gt;= 0</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>announcePosition</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>announceHoldTime</td>
                  <td>No</td>
                  <td>valid choice: yes no once</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the extension
              of the new call queue.
            </p>
            <p>Example:</p>
            <div class="code">503</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>
                    A call queue with the specified extension already exists
                  </td>
                </tr>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>A call queue with the specified name already exists</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Update Call Queue</h3>
        <div>
          <div>
            <p>This endpoint is used to update an existing call queue.</p>
            <p>
              <em>
                Note: Many of the call queue attributes are normally configured
                in FreePBX and are not managed or used by the EQ software
                directly. See the FreePBX queue configuration page for more
                information on any attributes that aren't self-explanatory.
              </em>
            </p>
          </div>
          <div class="url">PUT call-queue/[extension]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension of the call queue</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of call queue to create
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-35 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>type</td>
                  <td>Yes</td>
                  <td>valid type code: 0 (inbound) 1 (outbound)</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>autoAnswerAcd</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sysResume</td>
                  <td>No</td>
                  <td>integer &gt; 0 (seconds), or null to disable</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>wrapUpTime</td>
                  <td>No</td>
                  <td>
                    integer &gt; 0 (seconds), 0 for unlimited, or null to
                    disable
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>wrapUpAllQueues</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>cidNamePrefix</td>
                  <td>No</td>
                  <td>string, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>agentRestrictions</td>
                  <td>No</td>
                  <td>
                    valid choice: 0 (Call as Dialed) 1 (No Follow-Me or Call
                    Forward) 2 (Extensions Only)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>ringStrategy</td>
                  <td>No</td>
                  <td>
                    valid choice: ringall leastrecent fewestcalls random
                    rrmemory rrordered linear wrandom
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>weight</td>
                  <td>No</td>
                  <td>integer 0-10</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>mohClass</td>
                  <td>No</td>
                  <td>valid choice: inherit default none</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>ringingMoh</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recordingFormat</td>
                  <td>No</td>
                  <td>
                    "wav", or blank/null to disable
                    <em>
                      Note: FreePBX lists two other formats that are not
                      supported by EQ and should not be used.
                    </em>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recordingMode</td>
                  <td>No</td>
                  <td>valid choice: includeholdtime afteranswered</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>maxWaitTime</td>
                  <td>No</td>
                  <td>integer &gt; 0, or null for unlimited</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>agentTimeout</td>
                  <td>No</td>
                  <td>integer &gt; 0, or null for unlimited</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>agentRetryTime</td>
                  <td>No</td>
                  <td>integer &gt;= 0, or null for no retry</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>maxCallers</td>
                  <td>No</td>
                  <td>integer &gt; 0, or null for unlimited</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>joinEmpty</td>
                  <td>No</td>
                  <td>valid choice: yes no strict ultrastrict loose</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>leaveEmpty</td>
                  <td>No</td>
                  <td>valid choice: yes no strict ultrastrict loose</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>announceFrequency</td>
                  <td>No</td>
                  <td>integer &gt;= 0</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>announcePosition</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>announceHoldTime</td>
                  <td>No</td>
                  <td>valid choice: yes no once</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>
                    A call queue with the specified extension already exists
                  </td>
                </tr>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>A call queue with the specified name already exists</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Call Queue</h3>
        <div>
          <div>
            <p>
              This endpoint is used to delete a certain call queue. The queue is
              removed completely form Asterisk, but the call queue is not
              actually deleted in the EQ system, but is flagged as deleted and
              treated accordingly.
            </p>
          </div>
          <div class="url">DELETE call-queue/[extension]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension of the call queue</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Undelete Call Queue</h3>
        <div>
          <div>
            <p>This endpoint is used to undelete a certain call queue.</p>
          </div>
          <div class="url">PUT call-queue/[extension]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>Extension of the call queue</td>
                </tr>
                <tr>
                  <td>Query String</td>
                  <td>undelete</td>
                  <td>Yes</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-pause_code"></a>
      <div class="endpoint">
        <h3>Get Pause Code</h3>
        <div>
          <div>
            <p>This endpoint is used to get a certain pause code.</p>
          </div>
          <div class="url">GET pause-code/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>ID of the pause code</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is a pause code
              object.
            </p>
            <p>Example:</p>
            <div class="code">
              &#123; "id":7, "name":"Lunch", "deleted":false,
              "datetimeLastUpdated":1358539596, "datetimeDeleted":null &#125;
            </div>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Create Pause Code</h3>
        <div>
          <div>
            <p>This endpoint is used to create a new pause code.</p>
          </div>
          <div class="url">POST pause-code</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of pause code to create
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-16 characters</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the ID of the
              new pause code (integer).
            </p>
            <p>Example:</p>
            <div class="code">7</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>A pause code with the specified name already exists</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Update Pause Code</h3>
        <div>
          <div>
            <p>This endpoint is used to update an existing pause code.</p>
          </div>
          <div class="url">PUT pause-code/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>ID of the pause code</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of pause code to update
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-16 characters</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>
                    Another pause code with the specified name already exists
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Pause Code</h3>
        <div>
          <div>
            <p>
              This endpoint is used to delete a certain pause code. The pause
              code is not actually deleted, but is flagged as deleted and
              treated accordingly.
            </p>
          </div>
          <div class="url">DELETE pause-code/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>ID of the pause code</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Undelete Pause Code</h3>
        <div>
          <div>
            <p>This endpoint is used to undelete a certain pause code.</p>
          </div>
          <div class="url">PUT pause-code/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>ID of the pause code</td>
                </tr>
                <tr>
                  <td>Query String</td>
                  <td>undelete</td>
                  <td>Yes</td>
                  <td>1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-extension"></a>
      <div class="endpoint">
        <h3>Get Extension</h3>
        <div>
          <div>
            <p>This endpoint is used to get a certain user extension.</p>
          </div>
          <div class="url">GET extension/[extension]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is a extension
              object.
            </p>
            <p>Example:</p>
            <div class="code">
              &#123; "extension": "604", "name": "Bill Smith", "cidMasquerade":
              null, "outboundCid": null, "emergencyCid": null, "callWaiting":
              true, "recording": &#123; "inboundExternal": "dontcare",
              "inboundInternal": "dontcare" "outboundExternal": "dontcare",
              "outboundInternal": "dontcare", "onDemand": true &#125;, "sip":
              &#123; "secret": null, "dtmfMode": "rfc2833", "context":
              "from-internal", "nat": "no" &#125;, "voicemail": null &#125;
            </div>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Create Extension</h3>
        <div>
          <div>
            <p>This endpoint is used to create a new user extension.</p>
          </div>
          <div class="url">POST extension</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of extension to create
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>digits only, not starting with 0</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-50 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>cidMasquerade</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>outboundCid</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emergencyCid</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>callWaiting</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.inboundExternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.inboundInternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.outboundExternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.outboundInternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.onDemand</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.secret</td>
                  <td>No</td>
                  <td>string, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.dtmfMode</td>
                  <td>No</td>
                  <td>
                    valid choice: rfc2833 (RFC 2833) inband (In band audio) auto
                    (Auto) info (SIP INFO (application/dtmf-relay) shortinfo
                    (SIF INFO (application/dtmf)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.context</td>
                  <td>No</td>
                  <td>string, or blank/null for default ("from-internal")</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.nat</td>
                  <td>No</td>
                  <td>
                    valid choice: yes (Yes) no (No - RFC3581) never (never - no
                    RFC3581) route (route - NAT no rport)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail</td>
                  <td>No</td>
                  <td>
                    JSON object containing voicemail config, or null to disable
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.context</td>
                  <td>No</td>
                  <td>string, or blank/null for default ("default")</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.password</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.emailAddress</td>
                  <td>No</td>
                  <td>valid email address, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.pagerEmailAddress</td>
                  <td>No</td>
                  <td>valid email address, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options</td>
                  <td>No</td>
                  <td>
                    JSON object containing voicemail options with option names
                    as keys and option values as values, or null for defaults
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.attach</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.saycid</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.envelope</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.delete</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the new
              extension.
            </p>
            <p>Example:</p>
            <div class="code">604</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>
                    A extension with the specified extension already exists
                  </td>
                </tr>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>A extension with the specified name already exists</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Update Extension</h3>
        <div>
          <div>
            <p>This endpoint is used to update an existing user extension.</p>
          </div>
          <div class="url">PUT extension/[extension]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>
                    JSON object containing attributes of extension to create
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>digits only, not starting with 0</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-50 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>cidMasquerade</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>outboundCid</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emergencyCid</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>callWaiting</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.inboundExternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.inboundInternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.outboundExternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.outboundInternal</td>
                  <td>No</td>
                  <td>valid choice: dontcare always never</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>recording.onDemand</td>
                  <td>No</td>
                  <td>boolean</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.secret</td>
                  <td>No</td>
                  <td>string, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.dtmfMode</td>
                  <td>No</td>
                  <td>
                    valid choice: rfc2833 (RFC 2833) inband (In band audio) auto
                    (Auto) info (SIP INFO (application/dtmf-relay) shortinfo
                    (SIF INFO (application/dtmf)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.context</td>
                  <td>No</td>
                  <td>string, or blank/null for default ("from-internal")</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>sip.nat</td>
                  <td>No</td>
                  <td>
                    valid choice: yes (Yes) no (No - RFC3581) never (never - no
                    RFC3581) route (route - NAT no rport)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail</td>
                  <td>No</td>
                  <td>
                    JSON object containing voicemail config, or null to disable
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.context</td>
                  <td>No</td>
                  <td>string, or blank/null for default</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.password</td>
                  <td>No</td>
                  <td>digits, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.emailAddress</td>
                  <td>No</td>
                  <td>valid email address, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.pagerEmailAddress</td>
                  <td>No</td>
                  <td>valid email address, or blank/null</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options</td>
                  <td>No</td>
                  <td>
                    JSON object containing voicemail options with option names
                    as keys and option values as values, or null for defaults
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.attach</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.saycid</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.envelope</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>voicemail.options.delete</td>
                  <td>No</td>
                  <td>valid choice: yes no</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>extension</td>
                  <td>in_use</td>
                  <td>
                    A extension with the specified extension already exists
                  </td>
                </tr>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>A extension with the specified name already exists</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Extension</h3>
        <div>
          <div>
            <p>This endpoint is used to delete a certain user extension.</p>
          </div>
          <div class="url">DELETE extension/[extension]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-phone"></a>
      <div class="endpoint">
        <h3>Get Phone</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the details of the phone for a
              certain extension.
            </p>
          </div>
          <div class="url">GET phone</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Query String</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is a phone object.
            </p>
            <p>Example:</p>
            <div class="code">
              &#123; "ipAddress": "192.168.2.200", "brand": "polycom", "model":
              "550", "firmwareVersion": "4.0.2.11307" // can be null &#125;
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-alert"></a>
      <div class="endpoint">
        <h3>Create Alert</h3>
        <div>
          <div>
            <p>This endpoint is used to create a new alert.</p>
          </div>
          <div class="url">POST alert</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>JSON object containing attributes of alert to create</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>user</td>
                  <td>No</td>
                  <td>
                    User ID of supervisor the alert is for, or null for
                    system-wide alert (custom notification type only)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-48 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>type</td>
                  <td>Yes</td>
                  <td>
                    valid alert type code: callQueueCallsHigh
                    callQueueWaitTimeHigh callQueueAgentsLow
                    callQueueAgentsAvailableLow emailQueueEmailsHigh
                    emailQueueAgentsLow emailQueueAgentsAvailableLow
                    licenseExceeded processStopped diskUsageHigh
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>callQueue</td>
                  <td>Yes, if applicable</td>
                  <td>Extension of call queue</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emailQueue</td>
                  <td>Yes, if applicable</td>
                  <td>ID of email queue</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>threshold</td>
                  <td>Yes, if applicable</td>
                  <td>integer</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>notificationType</td>
                  <td>Yes</td>
                  <td>
                    valid notification type string: realtime email sms custom
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>color</td>
                  <td>Yes, if applicable</td>
                  <td>
                    color to display alert as for realtime notification type
                    only RGB color string in format #XXXXXX
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>interval</td>
                  <td>Yes, if applicable</td>
                  <td>
                    minimum interval in minutes between alert notifications for
                    email, SMS, and custom notification types only
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>daysOfWeek</td>
                  <td>Yes</td>
                  <td>
                    days of week for which alert is active array of integers,
                    each 0-6: 0 = sunday 1 = monday 2 = tuesday 3 = wednesday 4
                    = thursday 5 = friday 6 = saturday Example for Monday,
                    Wednesday, and Friday: [1, 3, 5]
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>fromTime</td>
                  <td>Yes</td>
                  <td>
                    beginning of interval of time in day for which alert is
                    active string in format HH:MM AM or HH:MM PM
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>toTime</td>
                  <td>Yes</td>
                  <td>
                    end of interval of time in day for which alert is active
                    string in format HH:MM AM or HH:MM PM use 12:00 AM for very
                    end of day
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              If the request is successful, the returned data is the ID of the
              new alert (integer).
            </p>
            <p>Example:</p>
            <div class="code">32</div>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>
                    An alert with the specified name already exists for the user
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Update Alert</h3>
        <div>
          <div>
            <p>This endpoint is used to update an existing alert.</p>
          </div>
          <div class="url">PUT alert/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>ID of the alert</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>data</td>
                  <td>Yes</td>
                  <td>JSON object containing attributes of alert to update</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>name</td>
                  <td>Yes</td>
                  <td>1-48 characters</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>type</td>
                  <td>Yes</td>
                  <td>
                    valid alert type code: callQueueCallsHigh
                    callQueueWaitTimeHigh callQueueAgentsLow
                    callQueueAgentsAvailableLow emailQueueEmailsHigh
                    emailQueueAgentsLow emailQueueAgentsAvailableLow
                    licenseExceeded processStopped diskUsageHigh
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>callQueue</td>
                  <td>Yes, if applicable</td>
                  <td>Extension of call queue</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>emailQueue</td>
                  <td>Yes, if applicable</td>
                  <td>ID of email queue</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>threshold</td>
                  <td>Yes, if applicable</td>
                  <td>integer</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>notificationType</td>
                  <td>Yes</td>
                  <td>valid notification type: realtime email sms custom</td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>color</td>
                  <td>Yes, if applicable</td>
                  <td>
                    color to display alert as for realtime notification type
                    only RGB color string in format #XXXXXX
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>interval</td>
                  <td>Yes, if applicable</td>
                  <td>
                    minimum interval in minutes between alert notifications for
                    email, SMS, and custom notification types only
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>daysOfWeek</td>
                  <td>Yes</td>
                  <td>
                    days of week for which alert is active array of integers,
                    each 0-6: 0 = sunday 1 = monday 2 = tuesday 3 = wednesday 4
                    = thursday 5 = friday 6 = saturday Example for Monday,
                    Wednesday, and Friday: [1, 3, 5]
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>fromTime</td>
                  <td>Yes</td>
                  <td>
                    beginning of interval of time in day for which alert is
                    active string in format HH:MM AM or HH:MM PM
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>data</strong> parameter (JSON object)
                  </td>
                  <td>toTime</td>
                  <td>Yes</td>
                  <td>
                    end of interval of time in day for which alert is active
                    string in format HH:MM AM or HH:MM PM use 12:00 AM for very
                    end of day
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>name</td>
                  <td>in_use</td>
                  <td>
                    An alert with the specified name already exists for the user
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="endpoint">
        <h3>Delete Alert</h3>
        <div>
          <div>
            <p>This endpoint is used to delete a certain alert permanently.</p>
          </div>
          <div class="url">DELETE alert/[id]</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>id</td>
                  <td>Yes</td>
                  <td>ID of the alert</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-active_supervisor_sessions"></a>
      <div class="endpoint">
        <h3>Get Active Supervisor Sessions</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the details of active supervisor
              sessions.
            </p>
          </div>
          <div class="url">GET active-supervisor-sessions</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is an array of supervisor session objects.</p>
            <p>Example:</p>
            <div class="code">
              [ &#123; "token":
              "1a52c89e98e25adece7d6e47c67d1f10d89d294d105df19d7bcb1ca8d8f7520d",
              "user": 2, "extension": null, "pcless": false, "ipAddress":
              "172.16.0.5", "datetimeLastRefreshed": 1389216550, "active": true
              &#125; ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-active_agent_sessions"></a>
      <div class="endpoint">
        <h3>Get Active Agent Sessions</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the details of active agent sessions.
            </p>
          </div>
          <div class="url">GET active-agent-sessions</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is an array of agent session objects.</p>
            <p>Example:</p>
            <div class="code">
              [ &#123; "token":
              "32b227dc4d2bd6e28543305db91aa679a6a576606a284732344cb1e9c9a60497",
              "user": 3, "extension": "604", "pcless": false, "ipAddress":
              "172.16.0.5", "datetimeLastRefreshed": 1389216540, "active": true
              &#125; ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-queued_calls"></a>
      <div class="endpoint">
        <h3>Get Queued Calls</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the details of calls in queue for a
              certain queue or all queues.
            </p>
          </div>
          <div class="url">GET queued-calls</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Query String</td>
                  <td>queue</td>
                  <td>No</td>
                  <td>Extension of the call queue, or blank for all queues</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is an array of queued call objects.</p>
            <p>Example (all queues):</p>
            <div class="code">
              [ &#123; "queue": "501", "position": 1, "callerId": &#123;
              "number": "604", "name": "Bill Smith" &#125;, "waitTime": 0
              &#125;, &#123; "queue": "502", "position": 1, "callerId": &#123;
              "number": "104", "name": "Bob Jones" &#125;, "waitTime": 6 &#125;
              ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-steal_queued_call"></a>
      <div class="endpoint">
        <h3>Steal Queued Call</h3>
        <div>
          <div>
            <p>
              This endpoint is used to steal a call from queue and transfer it
              to an extension.
            </p>
          </div>
          <div class="url">POST steal-queued-call</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>channel</td>
                  <td>Yes</td>
                  <td>Channel of the queued call to steal</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>destination</td>
                  <td>Yes</td>
                  <td>Extension or destination to which to send the call</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-parked_calls"></a>
      <div class="endpoint">
        <h3>Get Parked Calls</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the details of calls in the parking
              lot.
            </p>
          </div>
          <div class="url">GET parked-calls</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is an array of parked call objects.</p>
            <p>Example:</p>
            <div class="code">
              [ &#123; "channel": "SIP\/104-00000099", "extension": 701,
              "callerId": &#123; "number": "104", "name": "Bob Jones" &#125;,
              "fromExtension": 604, "fromAgent": 3, "timeout": 230 &#125;,
              &#123; "channel": "SIP\/104-0000009b", "extension": 702,
              "callerId": &#123; "number": "104", "name": "Bill Smith" &#125;,
              "fromExtension": 604, "fromAgent": null, "timeout": 294 &#125; ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-pick_up_parked_call"></a>
      <div class="endpoint">
        <h3>Pick Up Parked Call</h3>
        <div>
          <div>
            <p>
              This endpoint is used to pick up a parked call and transfer it to
              an extension.
            </p>
          </div>
          <div class="url">POST pick-up-parked-call</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>channel</td>
                  <td>Yes</td>
                  <td>Channel of the parked call to pick up</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>destination</td>
                  <td>Yes</td>
                  <td>Extension or destination to which to send the call</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
        </div>
      </div>
      <a name="endpoints-active_calls"></a>
      <div class="endpoint">
        <h3>Get Active Calls</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the details of active calls for a
              certain extension.
            </p>
          </div>
          <div class="url">GET active-calls</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Query String</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension for which to retrieve calls</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is an array of parked call objects.</p>
            <p>Example:</p>
            <div class="code">
              [ &#123; "channel":"SIP\/604-0000019c",
              "datetimeStarted":1384457428, "uniqueId":"1384457427.423",
              "queue":null, "callerId": &#123; "number":"804", "name":"Alex
              Softphone" &#125;, "called":"604", "hasNotes":false &#125; ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-call_recordings"></a>
      <div class="endpoint">
        <h3>Get Call Recordings</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the file paths of call recordings for
              a certain call.
            </p>
          </div>
          <div class="url">GET call-recordings</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Query String</td>
                  <td>call</td>
                  <td>Yes (unless call unique ID specified)</td>
                  <td>ID of the call for which to retrieve recordings</td>
                </tr>
                <tr>
                  <td>Query String</td>
                  <td>call_unique_id</td>
                  <td>No (unless call ID not specified)</td>
                  <td>Unique IDof the call for which to retrieve recordings</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is an array of absolute file paths.</p>
            <p>Example:</p>
            <div class="code">
              [
              "\/var\/spool\/asterisk\/monitor\/2013\/12\/10\/manual-604-20131210-112425-1386696247.830.wav",
              "\/var\/spool\/asterisk\/monitor\/2013\/12\/10\/q-501-804-20131210-112407-1386696247.830.wav"
              ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-call_queue_agent_status"></a>
      <div class="endpoint">
        <h3>Get Call Queue Agent Status</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the call queue agent statuses for a
              certain queue, a certain agent, or all queues and agents.
            </p>
          </div>
          <div class="url">GET call-queue-agent-status</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Query String</td>
                  <td>queue</td>
                  <td>No</td>
                  <td>
                    Extension of a call queue, or blank for all queues or if
                    specifying agent
                  </td>
                </tr>
                <tr>
                  <td>Query String</td>
                  <td>agent</td>
                  <td>No</td>
                  <td>
                    User ID of an agent, or blank for all agents or if
                    specifying queue
                  </td>
                </tr>
                <tr>
                  <td>Query String</td>
                  <td>combined</td>
                  <td>No</td>
                  <td>
                    0 or 1 (false or true) Value of 1 causes combined status
                    across all queues for each agent to be returned Takes affect
                    only if no queue or agent is specified
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              The returned data is an array of call queue agent status objects.
            </p>
            <p>Example (all queues and agents):</p>
            <div class="code">
              [ &#123; "queue": "504", "agent": 3, "agentExtension": "104",
              "skill": 3, "status": "in_use", "wrapUpPlanned": true, "wrapUp":
              false, "wrapUpQueue": "505", "wrapUpTimeRemaining": null,
              "onEmail": false, "onLiveChat": false, "paused": false,
              "sysPaused": false, "pauseCode": null, "pauseCodeName": null,
              "io": 1, "acd": false, "ani": "804", "callerIdName": "Alex
              Smartphone", "otherExtension": "804", "otherUser": null,
              "waitTime": null, "uniqueId": "1404158257.74",
              "manuallyRecording": false, "datetimeFirstOnCall": 1404158259,
              "duration": 61, "channelUniqueId": "1404158257.75",
              "channelApplication": "AppQueue", "channelApplicationData":
              "(Outgoing Line)", "channelConnectedLineNum": "804",
              "bridgedChannelUniqueId": "1404158257.74",
              "bridgedChannelApplication": "Queue",
              "bridgedChannelApplicationData": "505,t,,,,,,,,",
              "bridgedChannelConnectedLineNum": "104" &#125;, &#123; "queue":
              "505", "agent": 3, "agentExtension": "104", "skill": 3, "status":
              "in_use", "wrapUpPlanned": true, "wrapUp": false, "wrapUpQueue":
              "505", "wrapUpTimeRemaining": null, "onEmail": false,
              "onLiveChat": false, "paused": false, "sysPaused": false,
              "pauseCode": null, "pauseCodeName": null, "io": 1, "acd": true,
              "ani": "804", "callerIdName": "Alex Smartphone", "otherExtension":
              "804", "otherUser": null, "waitTime": null, "uniqueId":
              "1404158257.74", "manuallyRecording": false,
              "datetimeFirstOnCall": 1404158259, "duration": 62,
              "channelUniqueId": "1404158257.75", "channelApplication":
              "AppQueue", "channelApplicationData": "(Outgoing Line)",
              "channelConnectedLineNum": "804", "bridgedChannelUniqueId":
              "1404158257.74", "bridgedChannelApplication": "Queue",
              "bridgedChannelApplicationData": "505,t,,,,,,,,",
              "bridgedChannelConnectedLineNum": "104" &#125; ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-call_queue_agent_action"></a>
      <div class="endpoint">
        <h3>
          Execute Call Queue Agent Action (Sign In, Sign Out, Pause, Resume,
          Exit Wrap Up)
        </h3>
        <div>
          <div>
            <p>
              This endpoint is used to execute an action for an agent in a
              certain call queue or in all relevant call queues.
            </p>
          </div>
          <div class="url">POST call-queue-agent-action</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>action</td>
                  <td>Yes</td>
                  <td>
                    Valid action type: signIn signOut pause resume exitWrapUp
                  </td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>options</td>
                  <td>Yes</td>
                  <td>JSON object containing options for action</td>
                </tr>
                <tr>
                  <td>
                    <strong>options</strong> parameter (JSON object)
                  </td>
                  <td>agent</td>
                  <td>Yes</td>
                  <td>User ID of agent for which to execute action</td>
                </tr>
                <tr>
                  <td>
                    <strong>options</strong> parameter (JSON object)
                  </td>
                  <td>queue</td>
                  <td>No</td>
                  <td>
                    Extension of queue for which to execute action for agent If
                    not specified or null (and queues parameter not specified
                    either--signIn only), the action will be executed on all
                    relevant queues, meaning (according to action type): signIn:
                    sign in all bound queues signOut: sign out all queues
                    currently signed in pause: pause all queues currently signed
                    in resume: resume all queues currently signed in exitWrapUp:
                    exit wrap up for all queues currently signed in
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>options</strong> parameter (JSON object)
                  </td>
                  <td>queues</td>
                  <td>No</td>
                  <td>
                    Array of extensions of queues for which to sign agent into
                    Example: ["500", "501", "504"]
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>options</strong> parameter (JSON object)
                  </td>
                  <td>skill</td>
                  <td>No</td>
                  <td>
                    Skill level (penalty) with which to sign agent into queue
                    (lower number means less penalty == higher priority) 5 -
                    Lowest 4 - Below Normal 3 - Normal 2 - Above Normal 1 -
                    Highest or null for default (3 - Normal) Has no effect for
                    actions other than <strong>signIn</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>options</strong> parameter (JSON object)
                  </td>
                  <td>skills</td>
                  <td>No</td>
                  <td>
                    Array of skill levels (penalty) with which to sign agent
                    into queues (lower number means less penalty == higher
                    priority) when signing in multiple specific queues Size of
                    array must be equal to size of queues array Each array
                    element must be a valid skill integer: 5 - Lowest 4 - Below
                    Normal 3 - Normal 2 - Above Normal 1 - Highest Example: [3,
                    3, 5] Has no effect for actions other than{" "}
                    <strong>signIn</strong>
                    Also has no effect if queues parameter is not used
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>options</strong> parameter (JSON object)
                  </td>
                  <td>paused</td>
                  <td>No</td>
                  <td>
                    Whether to sign queues in as paused Has no effect for
                    actions other than <strong>signIn</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>options</strong> parameter (JSON object)
                  </td>
                  <td>pauseCode</td>
                  <td>No</td>
                  <td>
                    ID of pause code to use for pause action or null for no
                    pause code Has no effect for actions other than{" "}
                    <strong>pause</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>action</td>
                  <td>invalid</td>
                  <td>Action type not valid</td>
                </tr>
                <tr>
                  <td>agent</td>
                  <td>blank</td>
                  <td>Agent ID not specified</td>
                </tr>
                <tr>
                  <td>agent</td>
                  <td>nonexistent</td>
                  <td>Agent does not exist (or is deleted)</td>
                </tr>
                <tr>
                  <td>agent</td>
                  <td>not_signed_in</td>
                  <td>
                    Agent not signed in (to system, not to queue) / has no
                    active session
                  </td>
                </tr>
                <tr>
                  <td>queue</td>
                  <td>already_signed_in</td>
                  <td>
                    Agent already signed into queue (applies only to{" "}
                    <strong>signIn</strong> action with certain queue specified)
                  </td>
                </tr>
                <tr>
                  <td>queue</td>
                  <td>not_signed_in</td>
                  <td>
                    Agent not signed into queue (applies only to{" "}
                    <strong>signOut</strong>, <strong>pause</strong>,{" "}
                    <strong>resume</strong>, and <strong>exitWrapUp</strong>{" "}
                    actions with certain queue specified)
                  </td>
                </tr>
                <tr>
                  <td>pauseCode</td>
                  <td>nonexistent</td>
                  <td>
                    Pause code does not exist (applies only to{" "}
                    <strong>pause</strong> action with pause code specified)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a name="endpoints-call_queue_statistics"></a>
      <div class="endpoint">
        <h3>Get Call Queue Statistics</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get a summary of calls and agents for a
              certain queue or all queues.
            </p>
          </div>
          <div class="url">GET call-queue-statistics</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Query String</td>
                  <td>queue</td>
                  <td>No</td>
                  <td>Extension of the call queue, or blank for all queues</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>
              The returned data is a call queue statistics object, or an array
              of call queue statistics objects if returning data for all queues.
            </p>
            <p>Example (single queue):</p>
            <div class="code">
              &#123; "queue": "502", "calls": &#123; "queued": 0, "bounced": 0,
              "abandoned": 1, "completed": 2 &#125;, "durations": &#123;
              "currentMaxWait": 0, "maxWait": 0, "averageWait": 2,
              "averageTalk": 1 &#125;, "agents": &#123; "signedIn": 1, "idle":
              0, "ringing": 1, "inUse": 0, "onHold": 0, "wrappingUp": 0,
              "paused": 1 &#125; &#125;
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-extension_action"></a>
      <div class="endpoint">
        <h3>Execute Extension Action (Dial, Blind Transfer, Park, Hang Up)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to dial, blind transfer, park, or hang up
              for an extension.
            </p>
          </div>
          <div class="url">POST extension-action</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>action</td>
                  <td>Yes</td>
                  <td>Valid action type: dial blindTransfer park hangUp</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>channel</td>
                  <td>No</td>
                  <td>
                    Extension's channel on which to act If not specified or
                    blank, first channel found for extension will be used For
                    blindTransfer, park, and hangUp types only
                  </td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>destination</td>
                  <td>Yes, if applicable</td>
                  <td>
                    Phone number or extension For dial and blindTransfer types
                    only
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>extension</td>
                  <td>nonexistent</td>
                  <td>Extension does not exist</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a name="endpoints-phone_command"></a>
      <div class="endpoint">
        <h3>Execute Phone Command (Press a Key)</h3>
        <div>
          <div>
            <p>This endpoint is used to press a button on a certain phone.</p>
          </div>
          <div class="url">POST phone-command</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>phone</td>
                  <td>Yes (unless extension specified)</td>
                  <td>
                    JSON object containing attributes for phone Must be a valid
                    phone object, as returned by the phone endpoint
                  </td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>extension</td>
                  <td>No</td>
                  <td>
                    Extension of phone Can be specified in place of a phone
                    object
                  </td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>key</td>
                  <td>Yes</td>
                  <td>
                    Valid key: line1, line2, ... soft_key1, soft_key2, ... up
                    down left right enter cancel volume_up volume_down headset
                    speaker mute menu messages applications directory dnd
                    conference transfer redial hold 1, 2, 3, 4, 5, 6, 7, 8, 9, 0
                    star pound
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>extension</td>
                  <td>nonexistent</td>
                  <td>Extension does not exist</td>
                </tr>
                <tr>
                  <td>phone</td>
                  <td>nonexistent</td>
                  <td>
                    Phone does not exist (extension not registered or phone type
                    undetectable)
                  </td>
                </tr>
                <tr>
                  <td>key</td>
                  <td>invalid</td>
                  <td>Key is not valid for phone</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a name="endpoints-call_recording_action"></a>
      <div class="endpoint">
        <h3>Execute Call Recording Action (Start, Stop, Cancel)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to manually record, stop recording, or
              cancel recording for an extension.
            </p>
          </div>
          <div class="url">POST call-recording-action</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>extension</td>
                  <td>Yes</td>
                  <td>Extension</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>action</td>
                  <td>Yes</td>
                  <td>Valid action type: start stop cancel</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>extension</td>
                  <td>nonexistent</td>
                  <td>Extension does not exist</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <a name="endpoints-call_monitor_action"></a>
      <div class="endpoint">
        <h3>Execute Call Monitor Action (Spy, Barge In, Whisper)</h3>
        <div>
          <div>
            <p>
              This endpoint is used to spy, barge in, or whisper to an
              extension.
            </p>
          </div>
          <div class="url">POST call-monitor-action</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Request Body</td>
                  <td>action</td>
                  <td>Yes</td>
                  <td>Valid action type: spy bargeIn whisper</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>targetExtension</td>
                  <td>Yes</td>
                  <td>Extension to monitor</td>
                </tr>
                <tr>
                  <td>Request Body</td>
                  <td>monitorExtension</td>
                  <td>Yes</td>
                  <td>Extension from which to monitor</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Special Errors</h4>
            <table>
              <thead>
                <tr>
                  <th>Parameter/Attribute</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>targetExtension</td>
                  <td>nonexistent</td>
                  <td>Target extension does not exist</td>
                </tr>
                <tr>
                  <td>monitorExtension</td>
                  <td>nonexistent</td>
                  <td>Monitor extension does not exist</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a name="endpoints-active_conferences"></a>
      <div class="endpoint">
        <h3>Get Active Conferences</h3>
        <div>
          <div>
            <p>
              This endpoint is used to get the statuses of any active
              conferences.
            </p>
          </div>
          <div class="url">GET active-conferences</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <table>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Name</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is an array of parked call objects.</p>
            <p>Example:</p>
            <div class="code">
              [ &#123; "conference": "3279", "statuses": [ &#123; 'userNumber'
              =&gt; 1, 'callerId' =&gt; [ 'number' =&gt; '104', 'name' =&gt;
              'Somebody', ], 'talking' =&gt; 'not_monitored', // 'yes', 'no', or
              'not_monitored' 'muted' =&gt; false, 'duration' =&gt; 132, &#125;
              ] &#125; ]
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-license"></a>
      <div class="endpoint">
        <h3>License</h3>
        <div>
          <div>
            <p>This endpoint is used to get the license details.</p>
          </div>
          <div class="url">GET license</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is a license object.</p>
            <p>Example:</p>
            <div class="code">
              &#123; "site":"Equilibrium Contact Center Inc.", "architecture":
              "64", "supervisors": &#123; "full": 10, "monitorOnly": 10 &#125;,
              "agents": &#123; "all": 20, "outbound": 3, "email": 10,
              "liveChat": 5 &#125;, "expires": 2082780000, "lastUpdated":
              1397836028 &#125;
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-license_usage"></a>
      <div class="endpoint">
        <h3>License Usage</h3>
        <div>
          <div>
            <p>This endpoint is used to get the current license usage.</p>
          </div>
          <div class="url">GET license-usage</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is a license usage object.</p>
            <p>Example:</p>
            <div class="code">
              &#123; "supervisors": &#123; "full": 0, "monitorOnly": 0 &#125;,
              "agents": &#123; "all": 1, "outbound": 0, "email": 0 &#125; &#125;
            </div>
          </div>
        </div>
      </div>
      <a name="endpoints-configuration_check"></a>
      <div class="endpoint">
        <h3>Configuration Check</h3>
        <div>
          <div>
            <p>
              This endpoint is used to check for problems in the configuration
              of the system.
            </p>
          </div>
          <div class="url">GET configuration-check</div>
          <div>
            <h4>Parameters/Attributes</h4>
            <p>[None]</p>
          </div>
          <div>
            <h4>Return Data</h4>
            <p>The returned data is a configuration check object.</p>
            <p>Example:</p>
            <div class="code">
              &#123; "filePermissions": &#123; "directories": &#123;
              "\/var\/lib\/php\/session": true,
              "\/var\/spool\/asterisk\/monitor": true,
              "\/var\/spool\/asterisk\/monitor\/emails": true,
              "\/var\/www\/html\/eq\/temp\/exports\/call_scripts": true,
              "\/var\/www\/html\/eq\/temp\/exports\/historical_reports": true
              &#125;, "threads": &#123; "\/opt\/eq\/eq": 1, "\/opt\/eq\/eqc": 1,
              "\/opt\/eq\/eqch": 1, "\/opt\/eq\/eqconf": 1, "\/opt\/eq\/eqd1":
              1, "\/opt\/eq\/eqd2": 1, "\/opt\/eq\/eqp": 1, "\/opt\/eq\/eqr": 1,
              "\/opt\/eq\/eqs": 1, "\/opt\/eq\/eqt": 1, "\/opt\/eq\/eqphpl": 1,
              "\/var\/www\/html\/eq\/scripts\/threads\/calculate_agent_compliance.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/convert_call_recordings.php":
              1, "\/var\/www\/html\/eq\/scripts\/threads\/main.php": 1,
              "\/var\/www\/html\/eq\/scripts\/threads\/manage_statuses.php": 1,
              "\/var\/www\/html\/eq\/scripts\/threads\/monitor_sessions.php": 1,
              "\/var\/www\/html\/eq\/scripts\/threads\/process_actions.php": 1,
              "\/var\/www\/html\/eq\/scripts\/threads\/process_calls.php": 1,
              "\/var\/www\/html\/eq\/scripts\/threads\/run_scheduled_historical_reports.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/send_alert_notifications.php":
              1, "\/var\/www\/html\/eq\/scripts\/threads\/summarize_data.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/sync_deprecated_tables.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/sync_with_asterisk.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/email\/process_incoming.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/email\/process_outgoing.php":
              1, "\/var\/www\/html\/eq\/scripts\/threads\/email\/ring.php": 1,
              "\/var\/www\/html\/eq\/scripts\/threads\/live_chat\/monitor_clients.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/live_chat\/process_chats.php":
              1, "\/var\/www\/html\/eq\/scripts\/threads\/live_chat\/ring.php":
              1,
              "\/var\/www\/html\/eq\/scripts\/threads\/live_chat\/update_statuses.php":
              1 &#125;, "cronJobs": [ ], "scripts": &#123;
              "\/var\/www\/html\/eq\/scripts\/db_backup.sh": true,
              "\/var\/www\/html\/eq\/scripts\/db_backupdel.sh": true,
              "\/var\/www\/html\/eq\/scripts\/disk_status.sh": true,
              "\/var\/www\/html\/eq\/scripts\/fix.php": true,
              "\/var\/www\/html\/eq\/scripts\/reload_freepbx.php": true,
              "\/var\/www\/html\/eq\/scripts\/run_scheduled_historical_report.php":
              true, "\/var\/www\/html\/eq\/scripts\/send_email_to_agent.php":
              true, "\/var\/www\/html\/eq\/scripts\/thread_start.sh": true,
              "\/var\/www\/html\/eq\/scripts\/thread_status.sh": true,
              "\/var\/www\/html\/eq\/scripts\/update.php": true,
              "\/var\/www\/html\/eq\/scripts\/agi\/eqcse\/main.php": true,
              "\/var\/www\/html\/eq\/scripts\/agi\/eqcse\/send_email.php": true,
              "\/var\/www\/html\/eq\/scripts\/agi\/eqcse\/send_text_message.php":
              true, "\/var\/www\/html\/eq\/scripts\/agi\/pcless\/pause.php":
              true, "\/var\/www\/html\/eq\/scripts\/agi\/pcless\/resume.php":
              true, "\/var\/www\/html\/eq\/scripts\/agi\/pcless\/sign_in.php":
              true, "\/var\/www\/html\/eq\/scripts\/agi\/pcless\/sign_out.php":
              true,
              "\/var\/www\/html\/eq\/scripts\/cron\/daily\/maintenance.sh":
              true, "\/var\/www\/html\/eq\/scripts\/cron\/daily\/report.php":
              true,
              "\/var\/www\/html\/eq\/scripts\/cron\/every1\/eq_recover.sh":
              true, "\/var\/www\/html\/eq\/scripts\/cron\/every1\/main.php":
              true,
              "\/var\/www\/html\/eq\/scripts\/cron\/every1\/send_alert_notifications.php":
              true,
              "\/var\/www\/html\/eq\/scripts\/custom\/nexvortex\/send_alert_notification.php":
              true &#125;, "libraries": &#123;
              "\/var\/www\/html\/eq\/libraries\/wkhtmltopdf\/0.11.0_rc1\/wkhtmltopdf-amd64":
              true, "\/bin\/nice": true, "\/usr\/local\/bin\/lame": true &#125;,
              "links": &#123; "\/opt\/eq\/eqphp": true,
              "\/etc\/cron.every1\/eq.php": true,
              "\/var\/lib\/asterisk\/agi-bin\/main.php": true,
              "\/var\/lib\/asterisk\/agi-bin\/pause.php": true,
              "\/var\/lib\/asterisk\/agi-bin\/resume.php": true,
              "\/var\/lib\/asterisk\/agi-bin\/sign_in.php": true,
              "\/var\/lib\/asterisk\/agi-bin\/sign_out.php": true &#125; &#125;,
              "missingCLibraries": [ ], "systemConfig": &#123; "phpTimeZone":
              true, "email": true &#125;, "asteriskConfig": &#123; "fullLog":
              true &#125;, "callQueues": &#123; "503": &#123;
              "agentRestrictions": true, "eventWhenCalled": true,
              "eventMemberStatus": true &#125;, "504": &#123;
              "agentRestrictions": true, "eventWhenCalled": true,
              "eventMemberStatus": true &#125;, "501": &#123;
              "agentRestrictions": false, "eventWhenCalled": true,
              "eventMemberStatus": true &#125;, "502": &#123;
              "agentRestrictions": true, "eventWhenCalled": true,
              "eventMemberStatus": true &#125;, "505": &#123;
              "agentRestrictions": true, "eventWhenCalled": true,
              "eventMemberStatus": true &#125; &#125; &#125;
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Api;
