import React from 'react'
import freepbx from '../../../assets/images/freepbx.png';
import microsiplogo from '../../../assets/images/microsiplogo.png';
import tulkas from '../../../assets/images/tulkas.png';
import polycom_logo from '../../../assets/images/polycom_logo.png';

const Footer = () => {
  return (
    <div className='w-full h-36 bg-sky-300 flex flex-1 items-center justify-center'>
        <div className='flex flex-1.5 h-full items-center justify-start'>
            <h1 className='font-poppins font-black text-5xl px-6'>EQUEUES</h1>
            <img className='h-1/2 px-6' src={freepbx} alt="freepbx"/>
            <img className='h-1/2 px-6' src={microsiplogo} alt="microsiplogo"/>
            <img className='h-1/2 px-6' src={tulkas} alt="tulkas"/>
            <img className='h-1/2 px-6' src={polycom_logo} alt="polycom_logo"/>
        </div>

        <div className='flex flex-col flex-.5 h-full items-center justify-center font-poppins font-extrabold text-xs'>
            <h3>sales@equeues.com</h3>
            <h3>support@equeues.com</h3>
            <h3>(619) 354-8588</h3>
        </div>
    </div>
  )
}

export default Footer